import './main.scss';
import React, {useState} from 'react';
import {Navigate, Link} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import {useTranslation} from 'react-i18next';

import Footer from "../../../organisms/footer/footer";
import Gallery1 from "../../../../assets/img/gallery1.jpg";
import Gallery2 from "../../../../assets/img/gallery2.jpg";
import Gallery3 from "../../../../assets/img/gallery3.jpg";


const HacExperienceCatalog = () => {

  const {t} = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const [activeTab, setActiveTab] = useState('new');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header/>
          <main className="landing">
            <Wadsworth/>
            <section className="gallery">
              <div className="inner">
                <h2>{t('hac-landing-gallery-title')}</h2>
                <p>{t('hac-landing-gallery-text')}</p>

                <div className="tabs internal">
                  <ul>
                    <li className={activeTab === 'past' ? 'active' : ''}>
                      <span onClick={() => handleTabClick('past')}>{t('experiences-old')}</span>
                    </li>
                    <li className={activeTab === 'new' ? 'active' : ''}>
                      <span onClick={() => handleTabClick('new')}>{t('experiences-new')}</span>
                    </li>
                  </ul>
                </div>

                {activeTab === 'past' && (
                  <div className="past-experiences">
                    <ul>
                      <li>
                        <img src={Gallery1} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-cotedAzur')}: </strong>{t('hac-landing-gallery-text-cotedAzur')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `cotedAzur` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={Gallery2} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-mykonos')}: </strong>
                          {t('hac-landing-gallery-text-mykonos')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `mykonos` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                    </ul>

                  </div>
                )}

                {activeTab === 'new' && (
                  <div className="new-experiences">
                    <ul>
                      <li>
                        <img src={Gallery1} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-hahnenkamm')}: </strong>
                          <strong>{t('hac-landing-gallery-date-hahnenkamm')}</strong>
                          {t('hac-landing-gallery-text-hahnenkamm')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `hahnenkamm` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={Gallery2} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-bordeaux')}: </strong>
                          <strong>{t('hac-landing-gallery-date-bordeaux')}</strong>
                          {t('hac-landing-gallery-text-bordeaux')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `bordeaux` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={Gallery3} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-eastnor')}: </strong>
                          <strong>{t('hac-landing-gallery-date-eastnor')}</strong>
                          {t('hac-landing-gallery-text-eastnor')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `eastnor` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={Gallery3} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-catalonia')}: </strong>
                          <strong>{t('hac-landing-gallery-date-catalonia')}</strong>
                          {t('hac-landing-gallery-text-catalonia')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `catalonia` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                    </ul>

                  </div>
                )}


              </div>
            </section>

          </main>
          <Footer/>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}

    </>
  );
};

export default HacExperienceCatalog;
