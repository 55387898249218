import './modal-hac-show-contact.scss';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import apiClient from '../../../../utils/apiClient';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 592,
  width: '100%',
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalHacShowContact({ open, handleClose }) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('pma');
  const [isPrimaryContact, setIsPrimaryContact] = useState(true);
  const [primaryContact, setPrimaryContact] = useState('first_pma');
  const [secondaryContact, setSecondaryContact] = useState('second_pma');
  const [contacts, setContacts] = useState({});
  const [isFetched, setIsFetched] = useState(false); // Estado para rastrear si los datos ya han sido cargados
  const token = sessionStorage.getItem('token') || null;

  useEffect(() => {
    if (open && token && !isFetched) {
      apiClient
        .get(`${process.env.REACT_APP_API_URL}/hac/contact-info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setContacts(response.data);
          setIsFetched(true);
        })
        .catch((error) => {
          console.error('Error fetching contact info:', error);
        });
    }
  }, [open, token, isFetched]);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setPrimaryContact(tab === 'pma' ? 'first_pma' : 'first_sales_person');
    setSecondaryContact(tab === 'pma' ? 'second_pma' : 'second_sales_person');
  };

  const toggleContact = () => {
    const tempPrimaryContact = primaryContact;
    setPrimaryContact(secondaryContact);
    setSecondaryContact(tempPrimaryContact);
    setIsPrimaryContact(!isPrimaryContact);
  };

  const isContactEmpty = (contact) => {
    return (
      contacts &&
      contacts[contact] &&
      Object.values(contacts[contact]).every((value) => value === null)
    );
  };

  return (
    <Modal open={open} onClose={handleClose} className="modal-contact">
      <Box sx={style}>
        <button
          className="close"
          onClick={() => {
            handleClose();
          }}
        >
          <span className="icon-close"></span>
        </button>
        <div className="tabs">
          <ul>
            <li className={currentTab === 'pma' ? 'active' : ''}>
              <span onClick={() => handleTabChange('pma')}>
                {t('common-pma')}
              </span>
            </li>
            <li className={currentTab === 'sales' ? 'active' : ''}>
              <span onClick={() => handleTabChange('sales')}>
                {t('common-retailer')}
              </span>
            </li>
          </ul>
        </div>
        {isContactEmpty(primaryContact) && (
          <div className="card">
            <h2>{t('common-no-results')}</h2>
          </div>
        )}
        {!isContactEmpty(primaryContact) && contacts[primaryContact] && (
          <div className="card">
            <span className="name">
              {contacts[primaryContact].name}{' '}
              {contacts[primaryContact].last_name}
            </span>
            <ul>
              <li>
                <span className="icon-email"></span>{' '}
                {contacts[primaryContact].email}
              </li>
              <li>
                <span className="icon-phone"></span>{' '}
                {contacts[primaryContact].prefix_phone}{' '}
                {contacts[primaryContact].phone}
              </li>
              {contacts[primaryContact].country && (
                <li>
                  <span className="icon-pin"></span>{' '}
                  {contacts[primaryContact].city},{' '}
                  {t(`countries-${contacts[primaryContact].country}`)}
                </li>
              )}
              {contacts[primaryContact].area_code && (
                <li>
                  <span className="icon-pin"></span>{' '}
                  {contacts[primaryContact].city},{' '}
                  {t(`countries-${contacts[primaryContact].area_code}`)}
                </li>
              )}
            </ul>
          </div>
        )}
        {isContactEmpty(secondaryContact) && (
          <div className="secondary">
            <strong>
              {currentTab === 'pma'
                ? isPrimaryContact
                  ? t('common-pma-alt')
                  : t('common-primary-pma')
                : isPrimaryContact
                ? t('common-retailer-alt')
                : t('common-primary-retailer')}
            </strong>
            <div>{t('common-no-results')}</div>
          </div>
        )}
        {!isContactEmpty(secondaryContact) && contacts[secondaryContact] && (
          <div className="secondary">
            <strong>
              {currentTab === 'pma'
                ? isPrimaryContact
                  ? 'Alternative private mobility advisor'
                  : 'Primary private mobility advisor'
                : isPrimaryContact
                ? 'Alternative Retailer'
                : 'Primary Retailer'}
            </strong>
            <div>
              <span className="name">
                {contacts[secondaryContact].name}{' '}
                {contacts[secondaryContact].last_name}
              </span>
              <button className="btn text" onClick={toggleContact}>
                {t('common-show-contact')}
              </button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default ModalHacShowContact;
