import './legal.scss';
import React from 'react';
import {Link, Navigate} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import { useTranslation } from 'react-i18next';
import Footer from "../../../organisms/footer/footer";

const HacLegalPrint = () => {

  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header />
          <main className="landing">
            <Wadsworth />
            <section className="legal">
              <div className="inner">
                <Link to="/hac/main" className="back-button">
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')}
                </Link>
                <h2>{t('legal-print-title')}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('legal-print-text'),
                  }}
                ></p>
              </div>
            </section>
          </main>
          <Footer />
        </div>
      ) : (
        <Navigate to="/login" />
      )}

    </>
  );
};

export default HacLegalPrint;
