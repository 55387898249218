import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import Grid from '@mui/material/Unstable_Grid2';
import decodeToken from '../../../../utils/decode-token';
import { Alert, Snackbar } from '@mui/material';

function TablePma({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const [dataLoaded, setDataLoaded] = useState(false);

  const [pmas, setPmas] = useState([]);
  const [filteredPmas, setFilteredPmas] = useState([]);
  // const [anchorEl, setAnchorEl] = useState({});
  const [countries, setCountries] = useState([]);
  const [markets] = useState([
    { name: 'au-cz-sk', countries: ['AT', 'CZ', 'SK'] },
    { name: 'benelux', countries: ['BE', 'LUX', 'NE'] },
    { name: 'france', countries: ['FR'] },
    { name: 'germany', countries: ['DE'] },
    { name: 'iberia', countries: ['ES', 'PT'] },
    { name: 'italy', countries: ['IT'] },
    { name: 'switzerland', countries: ['CH'] },
    { name: 'turkey', countries: ['TR'] },
  ]);
  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/pmas`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const data = response.data;
          setPmas(data);
          setFilteredPmas(data);
          setDataLoaded(true);
          onDataLoaded();

          // Extract unique countries
          const uniqueCountries = [
            ...new Set(data.map((pma) => pma.area_code)),
          ];
          setCountries(uniqueCountries);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded, t]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === 'market') {
      const selectedMarket = markets.find((market) => market.name === value);
      if (selectedMarket) {
        setCountries(selectedMarket.countries);
      } else {
        const allCountries = [...new Set(pmas.map((pma) => pma.area_code))];
        setCountries(allCountries);
      }

      setFilters({
        ...filters,
        market: value,
        country: '',
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const applyFilters = () => {
    let filtered = [...pmas];
    if (filters.name) {
      filtered = filtered.filter(
        (pma) =>
          pma.name.toLowerCase().includes(filters.name.toLowerCase()) ||
          pma.last_name.toLowerCase().includes(filters.name.toLowerCase())
      );
    }
    if (filters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === filters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((pma) =>
          selectedMarket.countries.includes(pma.area_code)
        );
      }
    }
    if (filters.country) {
      filtered = filtered.filter((pma) => pma.area_code === filters.country);
    }
    setFilteredPmas(filtered);
  };

  // const handleClick = (id) => (event) => {
  //   setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  // };

  // const handleClose = () => {
  //   setAnchorEl({});
  // };

  return (
    <>
      {dataLoaded ? (
        pmas.length !== 0 ? (
          <>
            <div className="filters">
              <p>{t('common-search')}</p>
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={3}>
                  <div className="form-control">
                    <label htmlFor="name">
                      {t('common-name')}/{t('common-last-name')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={filters.name}
                      onChange={handleFilterChange}
                    />
                  </div>
                </Grid>
                {role === 'admin' && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="market">{t('common-market')}</label>
                      <select
                        name="market"
                        id="market"
                        className="normal"
                        value={filters.market}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-market')}</option>
                        {markets.map((market) => (
                          <option key={market.name} value={market.name}>
                            {t(`market-${market.name}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                {(role === 'admin' ||
                  role === 'nscChampion' ||
                  role === 'marketMD' ||
                  role === 'singleMarketChampion') && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="country">{t('common-country')}</label>
                      <select
                        name="country"
                        id="country"
                        className="normal"
                        value={filters.country}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-country')}</option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {t(`countries-${country}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                <Grid xs={12} sm={6} md={2}>
                  <div className="actions">
                    <button className="btn" onClick={applyFilters}>
                      {t('common-apply')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="summary">
              <strong>
                {t('common-total-results')}: <span>{filteredPmas.length}</span>
              </strong>
            </div>
            <div>
              {filteredPmas.length !== 0 ? (
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="user-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{t('common-name')}</TableCell>
                      <TableCell align="left">
                        {t('common-last-name')}
                      </TableCell>
                      <TableCell align="center">{t('common-email')}</TableCell>
                      <TableCell align="center">
                        {t('common-country')}
                      </TableCell>
                      <TableCell align="center">
                        {t('common-hacs-asigned')}
                      </TableCell>
                      {/*<TableCell align="center"></TableCell>*/}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPmas.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">
                          {t(`countries-${row.area_code}`)}
                        </TableCell>
                        <TableCell align="center">
                          {row.number_hac_assigned}
                        </TableCell>
                        {/* <TableCell align="right">
                          <span
                            className="icon-more"
                            aria-controls={
                              anchorEl[row.id] ? `menu${index}` : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              Boolean(anchorEl[row.id]) ? 'true' : undefined
                            }
                            onClick={handleClick(row.id)}
                          ></span>
                          <Menu
                            className="submenu"
                            id={`${row.id}`}
                            anchorEl={anchorEl[row.id]}
                            open={Boolean(anchorEl[row.id])}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={() => {}}>
                              {t('admin-main-tab2-option1')}
                            </MenuItem>
                            <MenuItem onClick={() => {}}>
                              {t('admin-main-tab2-option2')}
                            </MenuItem>
                          </Menu>
                        </TableCell>*/}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="no-result">
                  <h2 className="txt-center">{t('admin-main-title-empty')}</h2>
                </div>
              )}
            </div>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
            >
              <Alert severity="error" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('admin-main-tab2-title-empty')}
              <small>{t('admin-main-tab2-text-empty')}</small>
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
    </>
  );
}

export default TablePma;
