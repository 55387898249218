import './modal-hac-sixt.scss';
import React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 592,
  width: '50%',
  textAlign: 'justify',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalHacSixt({ open, handleClose }) {

  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={handleClose} className="modal-sixt">
      <Box sx={style}>
        <button
          className="close"
          onClick={() => {
            handleClose();
          }}
        >
          <span className="icon-close"></span>
        </button>
        <div>
          <p>{t('sixt-text')}
          </p>
          <p>
            <ul className="simple-list">
              <li>{t('sixt-details-1')}</li>
              <li>{t('sixt-details-2')}</li>
              <li>{t('sixt-details-3')}</li>
              <li>{t('sixt-details-4')}</li>
              <li>{t('sixt-details-5')}</li>
              <li>{t('sixt-details-6')}</li>
              <li>{t('sixt-details-7')}</li>
              <li>{t('sixt-details-8')}</li>
              <li>{t('sixt-details-9')}</li>
              <li>{t('sixt-details-10')}</li>
            </ul>
          </p>
          <p>
            {t('sixt-reservation')}
          </p>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalHacSixt;
