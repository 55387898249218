import './modal-hac-edit-preferences.scss';
import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { useForm } from 'react-hook-form';
import Input from '../../../atoms/input.js';
import Joi from 'joi';
import { Alert } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalPmaEditPreferences({
  open,
  handleClose,
  selectedPreference,
  hacId,
  onSave,
}) {
  const { t } = useTranslation();
  const [cat, setCat] = useState('category-1');
  const [id, setId] = useState('');
  const [isNewPreference, setIsNewPreference] = useState(true);
  const [message, setMessage] = useState('');
  const token = sessionStorage.getItem('token') || null;
  const [categories, setCategories] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { register, handleSubmit, setValue, formState } = useForm();

  const { errors } = formState;

  const resetForm = useCallback(() => {
    setCat('category-1');
    setValue('title', '');
    setValue('value', '');
    setId('');
    setIsNewPreference(true);
  }, [setCat, setValue, setId, setIsNewPreference]);

  useEffect(() => {
    if (selectedPreference) {
      setCat(selectedPreference.category_id || 'category-1');
      setValue('title', selectedPreference.title);
      setValue('value', selectedPreference.value);
      setId(selectedPreference.id || '');
      setIsNewPreference(!selectedPreference.id);
    } else {
      resetForm();
    }
  }, [
    selectedPreference,
    setCat,
    setValue,
    resetForm,
    setId,
    setIsNewPreference,
  ]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/hac/preferences-categories`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    if (token) {
      fetchCategories();
    }
  }, [token]);

  const onSubmit = handleSubmit(async (data, event) => {
    event.preventDefault();
    const payload = { ...data, category_id: cat };
    if (id) {
      payload.id = id;
    }

    if (payload.value === '') {
      delete payload.value;
    }

    const url = `${process.env.REACT_APP_API_URL}/pma/hac-preference?hacId=${hacId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    };

    apiClient
      .post(url, payload, config)
      .then((response) => {
        if (response.status === 413) {
          setMessage('Payload too large');
          setOpenSnackbar(true);
        }
        handleClose();
        onSave(); // Llama al callback onSave después de guardar
        setMessage(t('hac-preferences-edit-feedback'));

        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error('Error al guardar los datos:', error);
        setMessage(t('common-internal-server-error'));
        setOpenSnackbar(true);
      });
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="edit-preferences">
        <Box sx={style}>
          <button
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className="icon-close"></span>
          </button>
          <form onSubmit={onSubmit}>
            <h2>
              {isNewPreference
                ? t('hac-preferences-edit-title-new')
                : t('hac-preferences-edit-title-edit')}
            </h2>
            <div className="form-control">
              <label htmlFor="cat">{t('common-category')}</label>
              <select
                className="normal"
                name="cat"
                id="cat"
                value={cat}
                onChange={(e) => setCat(e.target.value)}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {t(`hac-preferences-${category.id}`)}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-control required">
              <Input
                type="text"
                id="title"
                label={t('common-title')}
                error={errors?.title}
                {...register('title', {
                  validate: (v) => {
                    const { error } = Joi.string()
                      .max(255)
                      .required()
                      .error(() => new Error(t('hac-preferences-edit-error')))
                      .validate(v);
                    return error?.message ? error.message : true;
                  },
                })}
              />
            </div>
            <div className="form-control">
              <label htmlFor="value">{t('common-description')}</label>
              <textarea
                id="value"
                {...register('value')}
                className="txt-area"
              />
            </div>
            <div className="actions">
              <button
                type="button"
                className="btn secondary"
                onClick={handleClose}
              >
                {t('common-cancel')}
              </button>
              <button className="btn" type="submit">
                {t('common-save')}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalPmaEditPreferences;
