import React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalDataSaved({ open, handleClose, handleAceptModalDataSaved }) {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h2 id="modal-modal-title">{t('common-data-saved')}</h2>
        <p id="modal-modal-description">{t('alert-data-saved')}</p>
        <button
          className="btn"
          onClick={() => {
            handleAceptModalDataSaved();
          }}
        >
          Aceptar
        </button>
      </Box>
    </Modal>
  );
}

export default ModalDataSaved;
