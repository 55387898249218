import './nominating-retailer.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import axios from 'axios';
import ModalReassignSaleperson from '../../../organisms/role-admin/modal-reassign-saleperson/modal-reassign-saleperson';
import ModalEditSaleperson from '../../../organisms/role-admin/modal-edit-saleperson/modal-edit-saleperson';
import { Alert, Snackbar } from '@mui/material';

function NominatingRetailer() {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const { hacId } = useParams();

  const [contactInfo, setContactInfo] = useState(null);
  const [retailerId, setRetailerId] = useState(null);
  const [modalOpenReasign, setModalOpenReasign] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [currentSalesPerson, setCurrentSalesPerson] = useState(null);
  const [salesPersonType, setSalesPersonType] = useState(1); // Añadido para almacenar el tipo de salesperson

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/pma/contact-info/hac/${hacId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await axios.get(url, config);
        if (response.status === 200) {
          setContactInfo(response.data);
          setRetailerId(response.data.retailer.id);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    fetchData();
  }, [hacId, token, t]);

  const openModalReasign = (salesPerson, type) => {
    setCurrentSalesPerson(salesPerson);
    setSalesPersonType(type); // Establecer el tipo de salesperson
    setModalOpenReasign(true);
  };

  const closeModalReasign = () => {
    setModalOpenReasign(false);
    setCurrentSalesPerson(null);
  };

  const openModalEdit = (salesPerson) => {
    setCurrentSalesPerson(salesPerson);
    setModalOpenEdit(true);
  };
  const closeModalEdit = () => {
    setModalOpenEdit(false);
    setCurrentSalesPerson(null);
  };

  return (
    <>
      {token &&
      (role === 'admin' ||
        role === 'marketAdmin' ||
        role === 'countryAdmin') ? (
        <div className="App">
          <Header />
          <main>
            <section className="contact-info nominating-retailer">
              <div className="inner">
                <Link to="/admin/main/hac" className="back-button">
                  {' '}
                  <span className="icon-chevron-left"></span> {t('common-back')}
                </Link>
                <h2>{t('retailer-info-title')}</h2>
                {contactInfo ? (
                  <>
                    <h5>{t('retailer-info-title')}</h5>
                    <div className="retailer">
                      <dl>
                        <div>
                          <dt>{t('common-name')}</dt>
                          <dd>{contactInfo.retailer.name}</dd>
                        </div>
                        <div>
                          <dt>{t('common-country')}</dt>
                          <dd>{t(`countries-${contactInfo.retailer.area_code}`)}</dd>
                        </div>
                        <div>
                          <dt>{t('common-city')}</dt>
                          <dd>{contactInfo.retailer.city}</dd>
                        </div>
                        <div>
                          <dt>{t('common-retailer-code')}</dt>
                          <dd>{contactInfo.retailer.retailer_code}</dd>
                        </div>
                      </dl>
                    </div>
                    {contactInfo.retailer_gm && (
                      <div className="person">
                        <h5>{t('retailer-info-title-retailergm')}</h5>
                        <dl>
                          <div>
                            <dt>{t('common-name')}</dt>
                            <dd>
                              {contactInfo.retailer_gm.name}{' '}
                              {contactInfo.retailer_gm.last_name}
                            </dd>
                          </div>
                          <div>
                            <dt>{t('common-email')}</dt>
                            <dd>{contactInfo.retailer_gm.email}</dd>
                          </div>
                          <div>
                            <dt>{t('common-phone')}</dt>
                            <dd>
                              {contactInfo.retailer_gm.prefix_phone}{' '}
                              {contactInfo.retailer_gm.phone}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    )}
                    <h5>{t('retailer-info-title-saleperson1')}</h5>
                    <div className="person">
                      {contactInfo.first_sales_person && (
                        <>
                          <dl>
                            <div>
                              <dt>{t('common-name')}</dt>
                              <dd>
                                {contactInfo.first_sales_person.name}{' '}
                                {contactInfo.first_sales_person.last_name}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-email')}</dt>
                              <dd>{contactInfo.first_sales_person.email}</dd>
                            </div>
                            <div>
                              <dt>{t('common-phone')}</dt>
                              <dd>
                                {contactInfo.first_sales_person.prefix_phone}{' '}
                                {contactInfo.first_sales_person.phone}
                              </dd>
                            </div>
                          </dl>
                        </>
                      )}
                      <div className="actions">
                        {contactInfo.first_sales_person && (
                          <button
                            className="btn secondary"
                            onClick={() =>
                              openModalEdit(contactInfo.first_sales_person)
                            }
                          >
                            {t('retailer-info-action1')}
                          </button>
                        )}
                        <button
                          className="btn secondary"
                          onClick={() =>
                            openModalReasign(contactInfo.first_sales_person, 1)
                          }
                        >
                          {t('retailer-info-action2')}
                        </button>
                      </div>
                    </div>

                    <h5>{t('retailer-info-title-saleperson2')}</h5>
                    <div className="person">
                      {contactInfo.second_sales_person && (
                        <>
                          <dl>
                            <div>
                              <dt>{t('common-name')}</dt>
                              <dd>
                                {contactInfo.second_sales_person.name || 'N/A'}{' '}
                                {contactInfo.second_sales_person.last_name ||
                                  ''}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-email')}</dt>
                              <dd>
                                {contactInfo.second_sales_person.email || 'N/A'}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-phone')}</dt>
                              <dd>
                                {contactInfo.second_sales_person.prefix_phone ||
                                  'N/A'}{' '}
                                {contactInfo.second_sales_person.phone || ''}
                              </dd>
                            </div>
                          </dl>
                        </>
                      )}

                      <div className="actions">
                        {contactInfo.second_sales_person && (
                          <button
                            className="btn secondary"
                            onClick={() =>
                              openModalEdit(contactInfo.second_sales_person)
                            }
                          >
                            {t('retailer-info-action1')}
                          </button>
                        )}
                        <button
                          className="btn secondary"
                          onClick={() =>
                            openModalReasign(contactInfo.second_sales_person, 2)
                          }
                        >
                          {t('retailer-info-action2')}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </section>
          </main>
          <ModalReassignSaleperson
            open={modalOpenReasign}
            onClose={closeModalReasign}
            hacId={hacId}
            retailerId={retailerId}
            currentSalesPerson={currentSalesPerson}
            salesPersonType={salesPersonType}
          />
          <ModalEditSaleperson
            open={modalOpenEdit}
            onClose={closeModalEdit}
            currentSalesPerson={currentSalesPerson}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default NominatingRetailer;
