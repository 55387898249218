import './preferences.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import { Link } from 'react-router-dom';
import Preferences from '../../../organisms/role-hac/hac-preferences/preferences-hac';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import {Alert, Snackbar} from "@mui/material";

const HacPreferences = () => {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const decodedToken = decodeToken(token);
  const role = decodedToken.role;
  const hacId = decodedToken.id;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cat = params.get('cat');
  const categories = useMemo(
    () => location.state?.categories || [],
    [location.state?.categories]
  );

  const [activeItem, setActiveItem] = useState(null);
  const [preferencesCount, setPreferencesCount] = useState({});

  useEffect(() => {
    if (cat) {
      const index = categories.findIndex((item) => item.id === cat);
      if (index !== -1) {
        setActiveItem(index);
      }
    }
  }, [cat, categories]);

  useEffect(() => {
    const fetchPreferencesCounts = async () => {
      try {
        const counts = {};
        for (const category of categories) {
          const url = `${process.env.REACT_APP_API_URL}/hac/category-preferences/${hacId}?categoryId=${category.id}`;
          const response = await apiClient.get(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          counts[category.id] = response.data.length;
        }
        setPreferencesCount(counts);
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    if (categories.length > 0) {
      fetchPreferencesCounts();
    }
  }, [categories, token, hacId, t]);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header />
          <main className="preferences">
            <Wadsworth />
            <section>
              <div className="inner menu">
                <aside className="lateral-menu">
                  <h2>{t('hac-menu-preferences-title')}</h2>
                  <ul>
                    {Array.isArray(categories) &&
                      categories.map((item, index) => (
                        <li
                          key={item.id}
                          className={activeItem === index ? 'on' : ''}
                          onClick={() => handleItemClick(index)}
                        >
                          <Link
                            to={`/hac/preferences?cat=${item.id}`}
                            state={{ categories }}
                          >
                            {t(`hac-preferences-${item.id}`)}{' '}
                            ({preferencesCount[item.id] || 0})
                          </Link>
                        </li>
                      ))}
                  </ul>
                </aside>
                <main className="preferences">
                  <h6>{t(`hac-preferences-${cat}`)}</h6>
                  <p>{t(`hac-preferences-${cat}-text`)}</p>
                  <Preferences cat={cat} />
                </main>
              </div>
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>

      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default HacPreferences;
