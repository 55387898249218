import './vehicles.scss';
import Header from '../../../organisms/header/header.js';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import HacVehicles from '../../../organisms/role-retailer/hac-vehicles/hac-vehicles';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';

function RetailerVehicles() {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || '';
  const role = decodeToken(token).role;

  return (
    <>
      {
        (token && (role === 'retailer' || role === 'retailerGM')) ? (
        <div className="App">
          <Header />
          <main>
            <section className="vehicles">
              <div className="inner">
                <Link to="/retailer/main" className="back-button">
                  <span className="icon-chevron-left"></span> {t('common-back')}
                </Link>
                <h4>{t('hac-my-profile-vehicles-title')}</h4>
                <p>{t('hac-my-profile-vehicles-text')}</p>
                <HacVehicles />
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default RetailerVehicles;
